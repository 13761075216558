<template>
  <div class="wrapper">
    <HomeHeader />
    <section class="grid grid-cols-1 justify-items-end">
      <div class="home-project-grid mb-6">
        <WorkCard
          v-for="(item, i) in projects.home_project_cards"
          :key="i"
          :work="item.project"
        />
      </div>
      <NuxtLink class="p-medium uppercase url" to="/work">
        View All Works
        <img
          class="inline"
          src="~assets/images/icon-arrow-outward.svg"
          alt=""
        />
      </NuxtLink>
    </section>
    <section
      class="xl:pt-[241px] xl:pb-[56px] lg:pt-[214px] lg:pb-[71px] md:pt-[124px] md:pb-[19px] pt-[94px] pb-[76px]"
    >
      <img
        class="m-auto xl:mb-[115px] lg:mb-[90px] md:mb-[60px] mb-[30px] xl:w-[476px] xl:h-[483px] md:w-[300px] md:h-[304px] w-[197px] h-[200px]"
        src="~assets/images/icon-smile-face-large.png"
        alt=""
      />
    </section>
    <WorkWithUs />
  </div>
</template>

<script setup>
const config = useRuntimeConfig();
const projects = await $fetch(
  `${config.public["baseURL"]}/api/home-project/list`
);
</script>
<style lang="scss" scoped>
.wrapper.first {
  display: block;
}
.home-project-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;
  > :nth-child(2) {
    grid-column-start: 4;
  }
  > :nth-child(3) {
    grid-column-start: 2;
    grid-row-start: 2;
  }
  > :nth-child(4) {
    grid-column-start: 3;
    grid-row-start: 2;
  }
}
.url {
  border-bottom: 1px solid $dark-grey;
  img {
    height: 13px;
  }
}
@media all and (max-width: $tablet) {
  .home-project-grid {
    grid-template-columns: repeat(3, 1fr);
    > :nth-child(2) {
      grid-column-start: 3;
    }
  }
}
@media all and (max-width: $mobile) {
  .home-project-grid {
    grid-template-columns: repeat(2, 1fr);
    > :nth-child(2) {
      grid-row-start: 2;
      grid-column-start: 2;
    }
    > :nth-child(3) {
      grid-row-start: 3;
      grid-column-start: 1;
    }
    > :nth-child(4) {
      grid-row-start: 3;
      grid-column-start: 2;
    }
  }
}
</style>
