<template>
  <div class="xl:pt-[325px] xl:pb-[180px] lg:pt-[115px] lg:pb-[96px] md:pt-[152px] md:pb-[30px] pt-[129px] pb-[110px] ">
    <section class="home-header hidden lg:block">
      <div class="row">
        <h1 class="fractul">Creating</h1>
        <img
          class="lightball"
          src="~assets/images/icon-red-lightball.svg"
          alt=""
        />
        <h1>intuitive</h1>
      </div>
      <div class="row">
        <h1>exper</h1>
        <img
          class="hourglass"
          src="~assets/images/icon-yellow-hourglass.svg"
          alt=""
        />
        <h1>ences designed</h1>
      </div>
      <div class="row">
        <h1>t</h1>
        <img class="face" src="~assets/images/icon-smile-face.svg" alt="" />
        <h1>&nbsp;elevate human&nbsp;</h1>
        <img class="heart" src="~assets/images/icon-outline-heart.svg" alt="" />
      </div>
      <div class="flex justify-between items-center">
        <div class="row">
          <h1>interacti</h1>
          <img
            class="rectangle"
            src="~assets/images/icon-rectangle.svg"
            alt=""
          />
          <h1>ons.</h1>
        </div>
        <p class="p-large max-w-[556px] hidden xl:block basis-gro-pro">
          Scratchpad Studio is a design group specializing in digital strategy,
          visual language, experience design, and development.
        </p>
        <p class="p-medium max-w-[405px] block xl:hidden">
          Scratchpad Studio is a design group specializing in digital strategy,
          visual language, experience design, and development.
        </p>
      </div>
    </section>
    <section class="home-header hidden md:block lg:hidden">
      <div class="row">
        <h2>Creating</h2>
        <img
          class="lightball"
          src="~assets/images/icon-red-lightball.svg"
          alt=""
        />
        <h2>intuitive</h2>
      </div>
      <div class="row">
        <h2>exper</h2>
        <img
          class="hourglass"
          src="~assets/images/icon-yellow-hourglass.svg"
          alt=""
        />
        <h2>ences</h2>
      </div>
      <div class="row">
        <h2>designed t</h2>
        <img class="face" src="~assets/images/icon-smile-face.svg" alt="" />
      </div>
      <div class="row">
        <h2>elevate human</h2>
        <img class="heart" src="~assets/images/icon-outline-heart.svg" alt="" />
      </div>
      <div class="row">
        <h2>interacti</h2>
        <img class="rectangle" src="~assets/images/icon-rectangle.svg" alt="" />
        <h2>ons.</h2>
      </div>
      <div class="hidden md:flex justify-end mt-6">
        <p class="p-medium max-w-[405px]">
          Scratchpad Studio is a design group specializing in digital strategy,
          visual language, experience design, and development.
        </p>
      </div>
    </section>
    <section class="home-header block md:hidden">
      <div class="row">
        <h3>Creating&nbsp;</h3>
        <img
          class="lightball"
          src="~assets/images/icon-red-lightball.svg"
          alt=""
        />
      </div>
      <h3>intuitive</h3>
      <div class="row">
        <h3>exper</h3>
        <img
          class="hourglass"
          src="~assets/images/icon-yellow-hourglass.svg"
          alt=""
        />
        <h3>ences</h3>
      </div>
      <div class="row">
        <h3>designed t</h3>
        <img class="face" src="~assets/images/icon-smile-face.svg" alt="" />
      </div>
      <div class="row">
        <h3>elevate human</h3>
      </div>
      <div class="row">
        <img class="heart" src="~assets/images/icon-outline-heart.svg" alt="" />
        <h3>interacti</h3>
        <img class="rectangle" src="~assets/images/icon-rectangle.svg" alt="" />
        <h3>ons.</h3>
      </div>
      <div class="row mt-6">
        <p class="p-medium max-w-[268px]">
          Scratchpad Studio is a design group specializing in digital strategy,
          visual language, experience design, and development.
        </p>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
export default{
  name: 'HomeHeader'
}
</script>

<style lang="scss" scoped>
.home-header {
  .row {
    display: flex;
    align-items: baseline;
  }
  img {
    height: 50px;
  }
  .lightball {
    width: 34px;
  }
  .hourglass {
    width: 33px;
  }
  .face {
    width: 50px;
  }
  .heart {
    width: 58px;
    height: 54px;
  }
  .rectangle {
    height: 48px;
    width: 107px;
  }
}
@media all and (max-width: $tablet) {
  .home-header {
    .lightball {
      width: 30px;
      height: 43px;
    }
    .hourglass {
      width: 30px;
      height: 44px;
    }
    .face {
      width: 44px;
      height: 44px;
    }
    .heart {
      width: 41px;
      height: 39px;
    }
    .rectangle {
      width: 89px;
      height: 40px;
    }
  }
}
@media all and (max-width: $mobile) {
  .home-header {
    .lightball {
      width: 20px;
      height: 29px;
    }
    .hourglass {
      width: 20px;
      height: 29px;
    }
    .face {
      width: 31px;
      height: 29px;
    }
    .heart {
      width: 49px;
      height: 26px;
    }
    .rectangle {
      height: 26px;
      width: 49px;
    }
  }
}
</style>
